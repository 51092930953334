import React from "react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "./Testimonials.css";

SwiperCore.use([Pagination, Navigation, Autoplay]);

const Testimonial = ({ avatar, quote, name, jobTitle }) => {
  return (
    <div class="testimonial">
      <div className="testimonial-quote">
        <div className="testimonial-quote-sub">
          <i class="fas fa-quote-left"></i>
        </div>
        <div class="testimonial-avatar-container">
          <img class="testimonial-avatar" src={avatar} alt="Avatar" />
        </div>
      </div>
      <div class="testimonial-content">
        <p class="testimonial-quote">{quote}</p>
        <p class="testimonial-name">{name}</p>
        <p class="testimonial-job-title">{jobTitle}</p>
      </div>
    </div>
  );
};
const testimonials = [
  {
    avatar: "images/profileicon3.jpg",
    quote:
      "I recently shopped at Chouhans for the first time and I was blown away by the selection and quality of clothes. The store was clean and well-organized, and the staff were friendly and helpful. I found some amazing deals on clothes that fit perfectly and looked great. I love that they offer a variety of sizes and styles, so there's something for everyone. I can't wait to come back and shop again!",
    name: "Shadakshari H.N",
    jobTitle: "Mysuru",
  },
  {
    avatar: "images/profileicon1.jpg",
    quote:
      "Chouhans is a hidden gem for fashion enthusiasts. With a well-curated selection of stylish and high-quality clothing, the store offers a delightful shopping experience. The knowledgeable staff provides excellent customer service.",
    name: "Srinivas",
    jobTitle: "Mysuru",
  },
];

const Testimonials = () => {
  return (
    <div>
      <h1 className="products-header">TESTIMONIALS</h1>
      <div className="swiper-container">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          autoplay={{
            delay: 13000,
            disableOnInteraction: true,
          }}
          // fadeEffect={{ crossFade: true }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <Testimonial {...testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
