import React from "react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "./Gallery.css";

SwiperCore.use([Pagination, Navigation, Autoplay]);

const Gallerys = ({ avatar, quote, name, jobTitle }) => {
  return (
    <div class="gallery">
      <div className="gallery-quote">
        <div className="gallery-quote-sub">
          <i class="fas fa-quote-left"></i>
        </div>
        <div class="gallery-avatar-container">
          <img class="gallery-avatar" src={avatar} alt="Avatar" />
        </div>
      </div>
      <div class="gallery-content">
        <p class="gallery-quote">{quote}</p>
        <p class="gallery-name">{name}</p>
        <p class="gallery-job-title">{jobTitle}</p>
      </div>
    </div>
  );
};
const gallerys = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2Fimg-8.jpg?alt=media&token=2bdd31e8-fa0b-4b62-aacc-287225378061",
    alt: "Myssuru",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2Fimg-9.jpg?alt=media&token=9eb861d1-7ebd-439e-b8c8-1f017140c4be",
    alt: "Mysuru",
  },
];

const Gallery = () => {
  return (
    <div>
      <h1 className="products-header">GALLERY</h1>
      <div className="swiper-container">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          autoplay={{
            delay: 6000,
            disableOnInteraction: true,
          }}
          // fadeEffect={{ crossFade: true }}
        >
          {gallerys.map((gallery, index) => (
            <SwiperSlide key={index}>
              <img
                style={{ width: "100%" }}
                src={gallery.src}
                alt={gallery.alt}
              />
              {/* <Gallerys {...gallery} /> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Gallery;
