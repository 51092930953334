import React, { useState } from "react";
import "./Locate.css";
// import MapComponent from "./Map";

function Locate() {
  return (
    <div>
      <div className="locate-us-container">
        <div className="locate-us-info">
          {/* <MapComponent
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          /> */}
          <h2>SHOP IN STORE</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3898.0937509907494!2d76.6434526269531!3d12.309473099999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf700c747fbac3%3A0x5460f5795964f82e!2sChouhans%20Peter%20England!5e0!3m2!1sen!2sin!4v1678882021414!5m2!1sen!2sin"
            width="600"
            height="350"
            style={{ width: "-webkit-fill-available", border: 0 }}
            allowfullscreen=""
            // loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <br />
        </div>
        <div className="locate-us-form">
          <h2>Chouhans</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet
            aliquam id diam maecenas ultricies mi eget mauris. At auctor urna
            nunc.
          </p> */}
          <a
            className="links"
            href="https://goo.gl/maps/zdw57QEE5MNihCeq8"
            aria-label="Twitter"
            target="_blank"
          >
            <i class="fas fa-map-marker-alt"></i>
            <div className="locate-us-links">
              119A, D Devaraj Urs Rd, opp. Bhima & Brothers, Mysuru, Karnataka
              570001
            </div>
          </a>
          <a className="links" href="tel:+918214280800" aria-label="call">
            <i class="fas fa-phone"></i>
            <div className="locate-us-links">+91-821-4280800</div>
          </a>
          <a
            className="links"
            href="mailto: chouhans1008@gmail.com"
            aria-label="Twitter"
          >
            <i class="fas fa-envelope"></i>
            <div className="locate-us-links">chouhans1008@gmail.com</div>
          </a>
          <div className="links">
            <i class="fas fa-clock"></i>
            <div className="locate-us-links">10:00 AM - 9:30 PM (Everyday)</div>
          </div>
        </div>
      </div>
      <div className="locate-us-container">
        <div className="locate-us-info">
          {/* <h2>Shop in store</h2> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3898.095671535931!2d76.64641701403706!3d12.30934373239538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf71ebb263add5%3A0xca074877db63f7b6!2sJAINAS%20PETER%20ENGLAND%20STORE!5e0!3m2!1sen!2sin!4v1678882824078!5m2!1sen!2sin"
            width="600"
            height="350"
            style={{ width: "-webkit-fill-available", border: 0 }}
            allowfullscreen=""
            // loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <br />
        </div>
        <div className="locate-us-form">
          <h2>Jainas by Chouhans</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet
            aliquam id diam maecenas ultricies mi eget mauris. At auctor urna
            nunc.
          </p> */}
          <a
            className="links"
            href="https://goo.gl/maps/mwjJcUDdAA45obSX6"
            aria-label="Twitter"
            target="_blank"
          >
            <i class="fas fa-map-marker-alt"></i>
            <div className="locate-us-links">
              58A, D Devaraj Urs Rd, Mysuru, Karnataka 570001
            </div>
          </a>
          <a className="links" href="tel:+918214280226" aria-label="Twitter">
            <i class="fas fa-phone"></i>
            <div className="locate-us-links">+91-821-4280226</div>
          </a>
          <a
            className="links"
            href="mailto: chouhans1008@gmail.com"
            aria-label="Twitter"
          >
            <i class="fas fa-envelope"></i>
            <div className="locate-us-links">chouhans1008@gmail.com</div>
          </a>
          <div className="links">
            <i class="fas fa-clock"></i>
            <div className="locate-us-links">10:00 AM - 9:30 PM (Everyday)</div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default Locate;
