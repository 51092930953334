import React, { useState, useEffect, useRef } from "react";
import "./About.css";
// import Team from "./Team.js";
// import { Paper } from "@material-ui/core";
import Team from "./Team";
import Gallery from "./Gallery";

export default function About() {
  const [numbers, setNumbers] = useState([
    { name: "Brands", value: 10, count: 1 },
    { name: "Categories", value: 20, count: 1 },
    { name: "Stores", value: 2, count: 1 },
  ]);

  const [numbers2, setNumbers2] = useState([
    { name: "Happy Customers", value: 40, count: 20 },
  ]);
  const brandsRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const element = brandsRef.current;
      const { top, bottom } = element.getBoundingClientRect();

      if (top >= 0 && bottom <= window.innerHeight + 100) {
        const interval = setInterval(() => {
          setNumbers((prevNumbers) =>
            prevNumbers.map((number) =>
              number.count >= number.value
                ? number
                : { ...number, count: number.count + 1 }
            )
          );
        }, 100);

        const interval2 = setInterval(() => {
          setNumbers2((prevNumbers) =>
            prevNumbers.map((number) =>
              number.count >= number.value
                ? number
                : { ...number, count: number.count + 1 }
            )
          );
        }, 100);

        return () => {
          clearInterval(interval);
          clearInterval(interval2);
        };
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);
  // const slider = useRef(null);
  // const itemWrap = useRef(null);
  // const pan = useRef(null);
  // const countRefTranslateX = useRef(0);

  // const handleOnRender = () => {
  //   const wh = window.innerHeight;
  //   pan.current.style.height = itemWrap.current.scrollWidth + wh / 1.25 + "px";
  // };

  // const handleScroll = () => {
  //   if (window.scrollY < pan.current?.offsetTop * 4) {
  //     // stop executing handleScroll after slider disappears from view while scrolling down
  //     if (window.scrollY <= pan.current.offsetTop) {
  //       slider.current.style.position = "relative";
  //     }
  //     if (window.scrollY >= slider.current.offsetTop) {
  //       slider.current.style.position = "fixed";
  //       countRefTranslateX.current = window.scrollY - pan.current.offsetTop;
  //       itemWrap.current.style.transform = `translate3d(-${countRefTranslateX.current}px, 0, 0)`;
  //     }
  //   }
  // };

  // useEffect(() => {
  //   handleOnRender();
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     window.removeEventListener("wheel", handleScroll);
  //   };
  // }, []);

  return (
    <div>
      <div className="about-us-container">
        <div className="about-us-info">
          <img
            style={{ width: "-webkit-fill-available" }}
            src="https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2Ffabric.jpeg?alt=media&token=e7d1a744-2d56-4a50-80e9-3c5e9deeb9cc"
            alt=""
          />
          <h2>Since 1986</h2>
          <br />
        </div>
        <div className="about-us-form">
          <p style={{ fontWeight: "bold", fontSize: "1.25em" }}>
            Welcome to Chouhans.
          </p>
          <br />
          <p>
            Established in the year 1986 as a wholesaler of jeans under the
            store name : Chouhans. Seeing the increase in the demand and change
            of trend, we started with premium quality Shirts and Trousers and
            soon rebranded the store as Chouhan's Shirts and Trousers.
          </p>
          <br />
          <p>
            We are passionate about bringing you the best selection of menswear
            from the biggest brands. With two stores, we are proud to have been
            serving our local community for over 30+ years.
          </p>
          <br />
          <p>
            In the year 2010, we tied up with Aditya Birla Fashion Retail
            Limited(ABFRL) to set-up the first Shop-In-Shop (SIS) of Peter
            England in India. This was our break-thru period and since then we
            have been one of the highest selling retailer of Peter England in
            Karnataka, an achievement we are extremely proud of. With the rise
            in branded ready made clothing in India, we started with Levi's,
            U.S.Polo Assn and since have been associated with them. On October
            2015, we inaugrated our new branch of retail store : JAINAS by
            Chouhans, yet another SIS, where we cater the brands : Peter
            England, U.S.Polo Assn, Arrow and Levi's. On diwali of 2022, we
            rebranded Chouhan's Shirts and Trousers as Chouhans, and
            simultaneously, tied up with Arvind Fashions Limited to be a
            retailer for Arrow, and tied up with Mohan Clothing to be a retailer
            for Blackberrys. Since the inception, we have been one of the
            fastest growing retailer of both Arrow and Blackberrys in Karnataka.
          </p>
          <br />
          <p>
            At our core, we believe that clothing is more than just a way to
            cover your body. It's a way to express your unique style and
            personality, and to feel confident and comfortable in your own skin.
            That's why we carefully curate our collections to include a wide
            range of styles, from classic to contemporary, and everything in
            between.
          </p>
          <br />
          <p>
            Our team of knowledgeable and friendly staff is dedicated to
            providing exceptional customer service. We understand that finding
            the perfect outfit can be a daunting task, and we are here to make
            your shopping experience as enjoyable and stress-free as possible.
          </p>
          <br />
          <p>
            We take pride in our commitment to quality and sustainability. We
            source our products from ethical and responsible suppliers, and we
            believe in supporting local businesses whenever possible. We believe
            that by making responsible choices, we can make a positive impact on
            our community and the world.
          </p>
          <br />
          <p>
            We are more than just a clothing store. We are a part of your
            community, and we are here to help you look and feel your best.
            Thank you for choosing us as your trusted source for menswear.
          </p>
        </div>
      </div>
      <div className="about-us-numbers" ref={brandsRef}>
        {/* <div style={{ display: "flex", fontWeight: "bold" }}> */}
        {numbers.map((number, index) => (
          <div
            key={index}
            // style={{
            //   margin: "0 0.5rem",
            //   borderRight: "1px solid black",
            //   padding: "0.5rem 2rem",
            // }}
            className="numbers-container"
          >
            <div style={{ fontSize: "3.5rem" }}>
              {number.count} <span className="numbers-span">+</span>
            </div>

            <div style={{ fontSize: "1.5rem" }}>{number.name}</div>
          </div>
        ))}
        {numbers2.map((number, index) => (
          <div
            key={index}
            className="numbers-container"
            //  style={{
            //     margin: "0 0.5rem",
            //     // borderRight: "1px solid black",
            //     padding: "0.5rem 2rem",
            //   }}
          >
            <div style={{ fontSize: "3.5rem" }}>
              {number.count}L<span className="numbers-span">+</span>
            </div>
            <div style={{ fontSize: "1.5rem" }}>{number.name}</div>
          </div>
        ))}
        {/* </div> */}
      </div>
      <div>
        <Gallery />
      </div>
      <br/>
      <br/>
      {/* <div>Should we add brands here ? https://hmgroup.com/about-us/</div> */}
      {/* // team block commented for now */}
      {/* <Team /> */}
    </div>
  );
}
