import React, { useEffect } from "react";
import "../../App.css";
import Product from "../ProductNew";
import Footer from "../Footer";

export default function Products() {
  useEffect(() => {
    document.title = "Chouhans | Products";
  }, []);
  return (
    <>
      <Product />
      <Footer />
    </>
  );
}
