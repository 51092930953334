import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      {/* <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Sign up to receive news and updates.
        </p>
        <p className="footer-subscription-text">
          You can unsubscribe at any time.
        </p>
        <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Email Address"
            />
            <Button buttonStyle="btn--outline">Sign up</Button>
          </form>
        </div>
      </section> 
      */
      /* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="social-icons">
            <a
              class="social-icon-link facebook"
              href="https://www.facebook.com/profile.php?id=100091263700319"
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </a>
            <a
              class="social-icon-link instagram"
              href="https://www.instagram.com/chouhans_mysuru/"
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </a>
            <a
              class="social-icon-link call"
              href="tel:+918214280800"
              target="_blank"
              aria-label="Call"
            >
              <i class="fas fa-phone"></i>
            </a>
            <a
              class="social-icon-link linkedin"
              // href="https://www.linkedin.com/in/chouhans-mysuru-045ab126b/"
              // href="https://www.linkedin.com/company/chouhans-shirts-and-trousers/"
              href ="https://www.linkedin.com/in/chouhans-mysuru-045ab126b/"
              target="_blank"
              aria-label="linkedin"
            >
              <i class="fab fa-linkedin" />
            </a>
            <a
              class="social-icon-link email"
              href="mailto: chouhans1008@gmail.com"
              target="_blank"
              aria-label="Email"
            >
              <i class="fas fa-envelope"></i>
            </a>
          </div>
          <div class="footer-logo">
            <Link to="/" className="social-logo">
              {/* Chouhans */}
              <img style={{ width: 200 }} src="chouhansLogo.svg"></img>
              {/* <i class="fab fa-typo3" /> */}
            </Link>
          </div>
          <small class="website-rights">
            The content of this site is copyright-protected and is the property
            of Chouhans
          </small>
          {/* <small class="website-rights">Chouhans © 2023</small> */}
          <small class="website-rights">Designed & Developed by Ratan Raigur</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
