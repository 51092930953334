import React, { useEffect } from "react";
import "../../App.css";
import Locate from "../Locate";
import Footer from "../Footer";

function LocateUs() {
  useEffect(() => {
    document.title = "Chouhans | Locate Us";
  }, []);
  return (
    <>
      <Locate />
      <Footer />
    </>
  );
}

export default LocateUs;
