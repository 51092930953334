import React, { useEffect, useState } from "react";
import "./Product.css";
import { Paper } from "@material-ui/core";
import map from "lodash/map";
import range from "lodash/range";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
// import shadows from "@material-ui/core/styles/shadows";

const firebaseConfig = {
  apiKey: "AIzaSyCNAkceGObRI-1Pb1UvxST5qQZyf9aqM0U",
  authDomain: "chouhans-b693b.firebaseapp.com",
  projectId: "chouhans-b693b",
  storageBucket: "chouhans-b693b.appspot.com",
  messagingSenderId: "1088935942146",
  appId: "1:1088935942146:web:3db57c5ab436ac670c6f1b",
  measurementId: "G-BWD59XH5G0",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const storageRef = storage.ref();

const brands = [
  { name: "Peter England", src: "/images/PeterEngland.png" },
  { name: "U.S.Polo", src: "/images/USPA.png" },
  { name: "Arrow", src: "/images/Arrows.png" },
  { name: "Levi's", src: "/images/Levis.png" },
  { name: "Blackberrys", src: "/images/Blackberryss.png" },
  { name: "Killer", src: "/images/Killer.png" },
  // { name: "Duke", src: "/images/Duke.png" },
  // { name: "Octave", src: "/images/Octave.png" },
  // { name: "Stori", src: "/images/Stori.png" },
  // { name: "Celcius", src: "/images/Celsius.png" },
  // { name: "Amstead", src: "/images/Amstead.png" },
  { name: "Jockey", src: "/images/Jockey.png" },
];
const topwear = [
  {
    name: "Casual Shirts",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fcasualshirt.jpg?alt=media&token=1edd7eae-4315-4275-a8c6-caf8258b11d9",
  },
  {
    name: "Formal Shirts",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fformalshirt.png?alt=media&token=c5d1c4cf-7791-46ef-85bd-e308b0e47fb1",
  },
  {
    name: "Party Wear Shirts",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fpartywear.png?alt=media&token=145d6d40-3e45-446a-8311-ba33543a0f12",
  },
  {
    name: "T-Shirts",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Ftshirt.jpg?alt=media&token=5764c502-d21c-4b36-b1a4-190c7a5c3bbd",
  },
  {
    name: "Polos",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fpolos.png?alt=media&token=d124afb2-82e9-40fc-a933-75a3c7215c1c",
  },
  {
    name: "Sweatshirts",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fsweatshirt.jpg?alt=media&token=fef8f9ac-50d3-40d0-9410-5b256e372601",
  },
  // {
  //   name: "Pullovers",
  //   designation: "Sales Executive",
  //   src: "prod/casualshirt.jpg",
  // },
  {
    name: "Sweaters",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fsweater.jpg?alt=media&token=3f13d602-543e-411e-bc1b-c0cbc3e31404",
  },
  {
    name: "Blazers",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fblazer.png?alt=media&token=8cc1122e-5394-4519-8c88-2b09ec8cf72c",
  },
  {
    name: "Jackets",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fjacket.jpg?alt=media&token=f396bdc4-c54d-4eff-a061-f8d83bef3d8e",
  },
  {
    name: "Suits",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fsuit.png?alt=media&token=9c3f33ec-7af9-40d5-8b01-d9b762410459",
  },
];
const bottomwear = [
  {
    name: "Formal Trousers",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fformaltrouser.jpg?alt=media&token=d837752a-0cc4-4deb-92dc-4ba453daf09f",
  },
  {
    name: "Chinos",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fchinos.png?alt=media&token=e9436ae8-76f5-47ae-8694-c3fef3adbe2d",
  },
  {
    name: "Jeans",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fjeans.jpg?alt=media&token=8e18fe19-c450-4bbb-b7fa-1b82f8ea51a9",
  },
  {
    name: "Track Pants",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Ftrackpant.jpg?alt=media&token=7567897a-4c46-4d18-ae4f-c820e4be691f",
  },
  {
    name: "Joggers",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fjogger.jpg?alt=media&token=7db60a94-4a97-4b77-bc10-6af583d87554",
  },
  {
    name: "Shorts & 3/4ths",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fshorts.png?alt=media&token=5e26ae4c-a1a6-4e39-b3a7-a21685df14c3",
  },
];
// const bottomwear = [
//   {
//     name: "Formal Trousers",
//     designation: "Sales Executive",
//     src: "prod/formaltrouser.jpg",
//   },
//   { name: "Chinos", designation: "Sales Executive", src: "prod/chinos.png" },
//   { name: "Jeans", designation: "Sales Executive", src: "prod/jeans.jpg" },
//   {
//     name: "Track Pants",
//     designation: "Sales Executive",
//     src: "prod/trackpant.jpg",
//   },
//   { name: "Joggers", designation: "Sales Executive", src: "prod/jogger.jpg" },
//   {
//     name: "Shorts & 3/4ths",
//     designation: "Sales Executive",
//     src: "prod/shorts.png",
//   },
// ];
const accessories = [
  {
    name: "Trunks",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Ftrunk.jpg?alt=media&token=c8861e16-eadc-491d-8a1e-ed6174d2561f",
  },
  {
    name: "Briefs",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fbrief.jpg?alt=media&token=713e6071-6749-4cd3-9267-adab0920ce66",
  },
  {
    name: "Vests",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fvest.jpg?alt=media&token=aeb4b6f6-026b-4016-8666-2637e348dee5",
  },
  {
    name: "Wallets",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fwallet.png?alt=media&token=b829a1a5-2e82-4d19-a500-4a0b503e5468",
  },
  {
    name: "Belts",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fbelt.png?alt=media&token=889490d1-1583-46ba-876f-4ffef6443327",
  },
  {
    name: "Socks",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fsocks.jpg?alt=media&token=75b0aa4a-aa96-4df7-964f-90fa707c57e8",
  },
  {
    name: "Hand-Kerchiefs",
    designation: "Sales Executive",
    src: "https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Products%2Fhankey.jpg?alt=media&token=53f52e67-0239-4799-99fc-08ee660bcfe7",
  },
];
export default function Product() {
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const folderRef = storageRef.child("Gallery");
        const imageList = await folderRef.listAll();
        // eslint-disable-next-line no-console
        console.log(imageList);

        const urlsPromises = imageList.items.map(async (imageRef) => {
          const url = await imageRef.getDownloadURL();
          //   const metadata = await imageRef.getMetadata();
          // eslint-disable-next-line no-console
          //   console.log(metadata);
          return {
            src: url,
            // width: metadata.width,
            // height: metadata.height,
          };
        });

        const urlss = await Promise.all(urlsPromises);
        // eslint-disable-next-line no-console
        console.log(urlss);
        setUrls(urlss);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    fetchImages();
  }, []);
  return (
    <div className="prod">
      <h1 className="products-header">PRODUCTS</h1>
      {/* <h1 className="products-subheader">Top Wear</h1>
      <div className="products-cards">
        {map(range(5), (_) => (
          <Container />
        ))}
      </div>
      <h1 className="products-subheader">Bottom Wear</h1>
      <div className="products-cards">
        {map(range(5), (_) => (
          <Container />
        ))}
      </div> */}
      <h1 className="products-subheader">Top Wear</h1>
      <div className="card-name-underline"></div>
      <div className="products-cards">
        {topwear.map((card) => (
          <Container card={card} />
        ))}
      </div>
      <h1 className="products-subheader">Bottom Wear</h1>
      <div className="card-name-underline" style={{ width: "5.5em" }}></div>
      <div className="products-cards">
        {bottomwear.map((card) => (
          <Container card={card} />
        ))}
      </div>
      <h1 className="products-subheader">Accessories</h1>
      <div className="card-name-underline" style={{ width: "5em" }}></div>
      <div className="products-cards">
        {accessories.map((card) => (
          <Container card={card} />
        ))}
      </div>
      {/* <br /> */}
      <br />
      <div style={{ padding: "2rem" }}>
        Check out all the freshest styles your closet needs in our men's
        clothing range. You'll find a roundup of everyday essentials, including
        tops and T-Shirts, as well as comfy lounge sets and underwear. Formal
        event coming up? Check out our men's blazers and suits for the sharpest
        looks and nail the dress code. When it comes to men's pants, there's
        chinos, joggers, formal trousers and cargo styles in all the staple
        colors. dreaming of denim? our men's jeans offer a range of fits to suit
        your style, including skinny, straight and tapered, to name just a few.
        Wear yours with a trendy oversized shirt or a classic denim number from
        our men's shirts edit. and when it comes to chilly weather, our men's
        jackets and sweaters have you covered.
      </div>
      <br />
      <h1 className="products-header">BRANDS</h1>
      <div className="brands-container">
        {brands.map((brand) => (
          <img alt="card-image" className="brand-image" src={brand.src}></img>
        ))}
      </div>
    </div>
  );
}

const Container = (card) => {
  console.log(card.card.name);
  return (
    <div
      style={{
        height: "350px",
        width: "250px",
        margin: "16px",
        // scrollSnapAlign: "start"
      }}
    >
      <Paper
        className="card-container"
        elevation={0}
        style={{ root: { boxShadow: "none" }, height: "350px", width: "250px" }}
      >
        <img className="card-image" src={card.card.src} alt="card-image" />
        <h3 className="card-name">{card.card.name}</h3>
        {/* <div className="card-name-underline"></div> */}
        {/* <p className="designation">aaaaaaaaaaaaaaaa</p> */}
      </Paper>
    </div>
  );
};
