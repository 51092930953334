import React, { useEffect } from "react";
import "../../App.css";
import About from "../About";
import Footer from "../Footer";

export default function AboutUs() {
  useEffect(() => {
    document.title = "Chouhans | About Us";
  }, []);
  return (
    <>
      <About />
      <Footer />
    </>
  );
}
