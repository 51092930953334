import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./TopSection.css";

function TopSection() {
  return (
    <div className="top-section-container">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1 style={{ fontFamily: "Sacramento, cursive" }}>Chouhans | Jainas</h1>
      <p>Exclusive clothing Store</p>
      {/* <div className='top-section-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          GET STARTED
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          WATCH TRAILER <i className='far fa-play-circle' />
        </Button>
      </div> */}
    </div>
  );
}

export default TopSection;
