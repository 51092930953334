import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const location = useLocation();

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [products, setProducts] = useState(false);
  const [contact, setContact] = useState(false);
  const [locate, setLocate] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = (title) => {
    setClick(false);
    // if (title === "home") {
    //   setHome(true);
    //   setAbout(false);
    //   setProducts(false);
    //   setContact(false);
    //   setLocate(false);
    // } else if (title === "products") {
    //   setHome(false);
    //   setAbout(false);
    //   setProducts(true);
    //   setContact(false);
    //   setLocate(false);
    // } else if (title === "contact") {
    //   setHome(false);
    //   setAbout(false);
    //   setProducts(false);
    //   setContact(true);
    //   setLocate(false);
    // } else if (title === "about") {
    //   setHome(false);
    //   setProducts(false);
    //   setAbout(true);
    //   setContact(false);
    //   setLocate(false);
    // } else if (title === "locate") {
    //   setHome(false);
    //   setProducts(false);
    //   setAbout(false);
    //   setContact(false);
    //   setLocate(true);
    // }
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    showButton();
    // Set initial state based on current URL
    switch (location.pathname) {
      case "/":
        setHome(true);
        setAbout(false);
        setProducts(false);
        setContact(false);
        setLocate(false);
        break;
      case "/products":
        setHome(false);
        setAbout(false);
        setProducts(true);
        setContact(false);
        setLocate(false);
        break;
      case "/about-us":
        setHome(false);
        setProducts(false);
        setAbout(true);
        setContact(false);
        setLocate(false);
        break;
      case "/contact-us":
        setHome(false);
        setProducts(false);
        setAbout(false);
        setContact(true);
        setLocate(false);
        break;
      case "/locate-us":
        setHome(false);
        setProducts(false);
        setAbout(false);
        setContact(false);
        setLocate(true);
        break;
      default:
        setHome(false);
        setAbout(false);
        setProducts(false);
        setContact(false);
        setLocate(false);
        break;
    }
  }, [location]);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link
            to="/"
            className="navbar-logo"
            onClick={() => closeMobileMenu("home")}
          >
            {/* Chouhans
            <i class="fab fa-typo3" /> */}
            <img style={{ width: 150 }} src="chouhansLogo.svg"></img>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {/* <li className="nav-item">
              <Link
                isActive
                to="/"
                className={home ? "nav-links-selected" : "nav-links"}
                onClick={() => closeMobileMenu("home")}
              >
                Home
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                to="/products"
                className={products ? "nav-links-selected" : "nav-links"}
                onClick={() => closeMobileMenu("products")}
              >
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about-us"
                className={about ? "nav-links-selected" : "nav-links"}
                // className={({ isActive }) => (isActive ? 'nav-links-selection' : 'nav-links')}
                onClick={() => closeMobileMenu("about")}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact-us"
                className={contact ? "nav-links-selected" : "nav-links"}
                onClick={() => closeMobileMenu("contact")}
              >
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/locate-us"
                className={locate ? "nav-links-selected" : "nav-links"}
                onClick={() => closeMobileMenu("locate")}
              >
                Locate
              </Link>
            </li>
            {/* <li>
              <Link
                to="/sign-up"
                className="nav-links-mobile"
                onClick={()=>closeMobileMenu(" ")}
              >
                Sign Up
              </Link>
            </li> */}
          </ul>
          {/* {button && <Button buttonStyle="btn--outline">SIGN UP</Button>} */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
