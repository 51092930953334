import React, { useState, useEffect, useRef } from "react";
import "./HomeContent.css";
import Testimonials from "./Testimonials.js";
// import Team from "./Team.js";
import { Link } from "react-router-dom";
// import { Paper } from "@material-ui/core";

export default function HomeContent() {
  const brands = [
    { name: "Peter England", src: "/images/PeterEngland.png" },
    { name: "U.S.Polo", src: "/images/USPA.png" },
    { name: "Arrow", src: "/images/Arrows.png" },
    { name: "Levi's", src: "/images/Levis.png" },
    { name: "Blackberrys", src: "/images/Blackberryss.png" },
    { name: "Killer", src: "/images/Killer.png" },
    // { name: "Duke", src: "/images/Duke.png" },
    // { name: "Octave", src: "/images/Octave.png" },
    // { name: "Stori", src: "/images/Stori.png" },
    // { name: "Celcius", src: "/images/Celsius.png" },
    // { name: "Amstead", src: "/images/Amstead.png" },
    { name: "Jockey", src: "/images/Jockey.png" },
  ];
  const [numbers, setNumbers] = useState([
    { name: "Brands", value: 10, count: 1 },
    { name: "Categories", value: 20, count: 1 },
    { name: "Stores", value: 2, count: 1 },
  ]);

  const [numbers2, setNumbers2] = useState([
    { name: "Happy Customers", value: 40, count: 20 },
  ]);
  const brandsRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const element = brandsRef.current;
      const { top, bottom } = element.getBoundingClientRect();

      if (top >= 0 && bottom <= window.innerHeight + 100) {
        const interval = setInterval(() => {
          setNumbers((prevNumbers) =>
            prevNumbers.map((number) =>
              number.count >= number.value
                ? number
                : { ...number, count: number.count + 1 }
            )
          );
        }, 100);

        const interval2 = setInterval(() => {
          setNumbers2((prevNumbers) =>
            prevNumbers.map((number) =>
              number.count >= number.value
                ? number
                : { ...number, count: number.count + 1 }
            )
          );
        }, 100);

        return () => {
          clearInterval(interval);
          clearInterval(interval2);
        };
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);
  // const slider = useRef(null);
  // const itemWrap = useRef(null);
  // const pan = useRef(null);
  // const countRefTranslateX = useRef(0);

  // const handleOnRender = () => {
  //   const wh = window.innerHeight;
  //   pan.current.style.height = itemWrap.current.scrollWidth + wh / 1.25 + "px";
  // };

  // const handleScroll = () => {
  //   if (window.scrollY < pan.current?.offsetTop * 4) {
  //     // stop executing handleScroll after slider disappears from view while scrolling down
  //     if (window.scrollY <= pan.current.offsetTop) {
  //       slider.current.style.position = "relative";
  //     }
  //     if (window.scrollY >= slider.current.offsetTop) {
  //       slider.current.style.position = "fixed";
  //       countRefTranslateX.current = window.scrollY - pan.current.offsetTop;
  //       itemWrap.current.style.transform = `translate3d(-${countRefTranslateX.current}px, 0, 0)`;
  //     }
  //   }
  // };

  // useEffect(() => {
  //   handleOnRender();
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     window.removeEventListener("wheel", handleScroll);
  //   };
  // }, []);

  return (
    <div className="home-main-container">
      <div className="home-container">
        <div className="home-info">
          <img
            style={{ width: "-webkit-fill-available" }}
            src="https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2Ffabric.jpeg?alt=media&token=e7d1a744-2d56-4a50-80e9-3c5e9deeb9cc"
            alt=""
          />
          <h2>Since 1986</h2>
          <br />
        </div>
        <div className="home-form">
          <p style={{ fontWeight: "bold", fontSize: "1.25em" }}>
            Welcome to Chouhans.
          </p>
          <br />
          <p>
            We are passionate about bringing you the best selection of menswear
            from the biggest brands. With two stores, we are proud to have been
            serving our local community for over 30+ years.
          </p>
          <br />
          <p>
            Our team of knowledgeable and friendly staff is dedicated to
            providing exceptional customer service. We understand that finding
            the perfect outfit can be a daunting task, and we are here to make
            your shopping experience as enjoyable and stress-free as possible.
          </p>
          <br />
          <Link to="/locate-us">
            <button>Locate Us</button>
          </Link>
        </div>
      </div>
      <div className="home-numbers" ref={brandsRef}>
        {/* <div style={{ display: "flex", fontWeight: "bold" }}> */}
        {numbers.map((number, index) => (
          <div
            key={index}
            // style={{
            //   margin: "0 0.5rem",
            //   borderRight: "1px solid black",
            //   padding: "0.5rem 2rem",
            // }}
            className="numbers-container"
          >
            <div style={{ fontSize: "3.5rem" }}>
              {number.count}
              <span className="numbers-span">+</span>
            </div>
            <div style={{ fontSize: "1.5rem" }}>{number.name}</div>
          </div>
        ))}
        {numbers2.map((number, index) => (
          <div
            key={index}
            className="numbers-container"
            //  style={{
            //     margin: "0 0.5rem",
            //     // borderRight: "1px solid black",
            //     padding: "0.5rem 2rem",
            //   }}
          >
            <div style={{ fontSize: "3.5rem" }}>
              {number.count}L<span className="numbers-span">+</span>
            </div>
            <div style={{ fontSize: "1.5rem" }}>{number.name}</div>
          </div>
        ))}
        {/* </div> */}
      </div>
      <div>
        <h1 className="products-header">BRANDS</h1>
        <div className="brands-container">
          {brands.map((brand) => (
            <img alt="card-image" className="brand-image" src={brand.src}></img>
          ))}
        </div>
      </div>
      {/* <div className="home-container"> */}
      <div className="testimonials-container">
        <Testimonials />
      </div>
      {/* </div> */}
      {/* <div className="footer-wrapper"> */}
      {/* <Footer /> */}
      {/* </div> */}
    </div>
  );
}
