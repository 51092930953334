import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1>MAGAZINE</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2FpeterEngland.jpg?alt=media&token=18171347-8c6c-40d1-a14c-9f346370f8ac"
              text="INSIDE CHOUHANS"
              title="Chouhans x Peter England"
              label="Adventure"
              // path="/services"
            />
            <CardItem
              src="https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2Flevis.jpg?alt=media&token=4bed4b92-0c8d-431f-81a0-ba6aaae6fa33"
              text="INSIDE CHOUHANS"
              label="Luxury"
              title="Chouhans x Vastram"
              // path="/services"
            />
          </ul>
          {/* <ul className="cards__items">
            <CardItem
              src="https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2Flevis.jpg?alt=media&token=4bed4b92-0c8d-431f-81a0-ba6aaae6fa33"

              text="Set Sail in the Atlantic Ocean visiting Uncharted Waters"
              label="Mystery"
              path="/services"
            />
            <CardItem
              src="https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2FpeterEngland.jpg?alt=media&token=18171347-8c6c-40d1-a14c-9f346370f8ac"
              text="Experience Football on Top of the Himilayan Mountains"
              label="Adventure"
              path="/products"
            />
            <CardItem
              src="https://firebasestorage.googleapis.com/v0/b/chouhans-b693b.appspot.com/o/Gallery%2Flevis.jpg?alt=media&token=4bed4b92-0c8d-431f-81a0-ba6aaae6fa33"

              text="Ride through the Sahara Desert on a guided camel tour"
              label="Adrenaline"
              path="/sign-up"
            />
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default Cards;
