import React, { useEffect } from "react";
import "../../App.css";
import Cards from "../Cards";
// import HeroSection from '../HeroSection';
import TopSection from "../TopSection";
import Footer from "../Footer";
import HomeContent from "../HomeContent";

function Home() {
  useEffect(() => {
    document.title = "Chouhans";
  }, []);
  return (
    <>
      <TopSection />
      <HomeContent />
      <Cards />
      <Footer />
    </>
  );
}

export default Home;
