import React, { useEffect } from "react";
import "../../App.css";
import Contact from "../Contact";
import Footer from "../Footer";

function ContactUs() {
  useEffect(() => {
    document.title = "Chouhans | Contact Us";
  }, []);
  return (
    <>
      <Contact />
      <Footer />
    </>
  );
}

export default ContactUs;
