import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Services from "./components/pages/Services";
import Products from "./components/pages/Products";
import SignUp from "./components/pages/SignUp";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import LocateUs from "./components/pages/LocateUs";
import { ScrollToTopOnRefresh, ScrollToTopOnRouteChange } from "./ScrollToTop";

function App() {
  // useEffect(() => {
  //   document.title = "Chouhans";
  // }, []);
  return (
    <>
      <Router>
        <ScrollToTopOnRefresh />
        <ScrollToTopOnRouteChange />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/products" component={Products} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/locate-us" component={LocateUs} />
          <Redirect path="/*" to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
