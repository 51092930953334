import React, { useState } from "react";
import "./Contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    // email: "",
    phone: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("idle");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    if (!formValues.name) {
      errors.name = "Please enter your name";
    }
    // if (!formValues.email) {
    //   errors.email = "Please enter your email address";
    // }
    if (!formValues.phone) {
      errors.phone = "Please enter your mobile number";
    }
    if (!formValues.message) {
      errors.message = "Please enter a message";
    }
    if (Object.keys(errors).length === 0) {
      // Form is valid - submit form
      setSubmissionStatus("submitting");
      console.log("Submitting form:", formValues);
      fetch("https://formsubmit.co/ajax/chouhans1008@gmail.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: formValues.name,
          message: formValues.message,
          phone: formValues.phone,
          email: formValues.email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setFormSubmitted(true);
          // setName(data.name);
          setSubmissionStatus("success");
        })
        .catch((error) => {
          console.log(error);
          setFormSubmitted(false);
          setSubmissionStatus("error");
        });
    } else {
      // Form is invalid - show errors
      setFormErrors(errors);
    }
  };

  return (
    <div className="contact-us-container">
      <div className="contact-us-info">
        <h2>CONTACT US</h2>
        <p>
          Email, call or complete the form to get in touch with us regarding any
          queries or requests.
        </p>
        <br />
        <a
          className="links"
          href="https://goo.gl/maps/zdw57QEE5MNihCeq8"
          aria-label="Twitter"
          target="_blank"
        >
          <i class="fas fa-map-marker-alt"></i>
          <div className="contact-us-links">
            119A, D Devaraj Urs Rd, opp. Bhima & Brothers, Mysuru, Karnataka
            570001
          </div>
        </a>
        <a
          className="links"
          href="https://goo.gl/maps/mwjJcUDdAA45obSX6"
          aria-label="Twitter"
          target="_blank"
        >
          <i class="fas fa-map-marker-alt"></i>
          <div className="locate-us-links">
            58A, D Devaraj Urs Rd, Mysuru, Karnataka 570001
          </div>
        </a>
        <a
          className="links"
          href="mailto: chouhans1008@gmail.com"
          aria-label="Twitter"
        >
          <i class="fas fa-envelope"></i>
          <div className="contact-us-links">chouhans1008@gmail.com</div>
        </a>
        <div style={{ display: "flex" }}>
          <i class="fas fa-phone"></i>
          <a className="links" href="tel:+918214280800" aria-label="Twitter">
            <div className="contact-us-links">+91-821-4280800</div>
          </a>
          <a className="links" href="tel:+918214280226" aria-label="Twitter">
            <div className="locate-us-links">+91-821-4280226</div>
          </a>
        </div>
      </div>
      <div className="contact-us-form">
        {!formSubmitted && submissionStatus === "idle" && (
          <form onSubmit={handleSubmit}>
            <label htmlFor="name"> Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            {formErrors.name && (
              <span className="error">{formErrors.name}</span>
            )}

            <label htmlFor="email">Email </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
            <label htmlFor="phone">Phone *</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formValues.phone}
              onChange={handleChange}
            />
            {formErrors.phone && (
              <span className="error">{formErrors.phone}</span>
            )}

            <label htmlFor="message">Message *</label>
            <textarea
              id="message"
              name="message"
              value={formValues.message}
              onChange={handleChange}
            ></textarea>
            {formErrors.message && (
              <span className="error">{formErrors.message}</span>
            )}

            <button type="submit">Submit</button>
          </form>
        )}
        {submissionStatus === "submitting" && (
          <div className="loading-container">
            <div className="loading-spinner" />
            <div>submitting</div>
          </div>
        )}
        {submissionStatus === "error" && (
          <div className="error-message">
            Oops, an error occured. Please try again.
          </div>
        )}
        {formSubmitted && (
          <div className="success-container">
            <i
              class="far fa-check-circle"
              // className="success-icon"
              style={{ fontSize: 48, marginBottom: 10 }}
            ></i>{" "}
            <div className="success-message">
              Thank you for your submission {formValues.name}! We'll get in
              touch shortly.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contact;
